import { useState } from "react";
import { hsbToRgb, rgbString } from '@shopify/polaris';

// Define types for Global object keys used for overriding Window object
declare global {
  interface Window { customOptions: any, initSetup: Function, init: Function, }
}

// TODO: Set types for custom events - TS has issues overiding default event listener object
function addCustomEvent(callee: any, el: any, event: any) {
  el.addEventListener(event, function () {
    el.removeEventListener(event, callee);
    return callee();
  }, { once: true });
}

export default function useDownload(templateData: Template, canvasRef: React.RefObject<CustomHTMLCanvasElement>) {

  const [isRecording, setIsRecording] = useState(false)
  const [isRecorded, setIsRecorded] = useState(false)
  const [recordedUrl, setRecordedUrl] = useState('')
  const [ isPlaying, setIsPlaying ] = useState(false);

  const createMediaRecorder = (): MediaRecorder => {
    const canvas = canvasRef.current;
    canvas && canvas.getContext("2d"); // https://bugzilla.mozilla.org/show_bug.cgi?id=1572422
    const stream = canvas && canvas.captureStream();
    const options = { mimeType: "video/webm" };
    const recorder = new MediaRecorder(stream as MediaStream, options);

    const recordedBlobs: Blob[] = [];

    recorder.ondataavailable = event => {
      recordedBlobs.push(event.data);
    };

    recorder.onstop = function () {
      var blob = new Blob(recordedBlobs, { 'type': 'video/webm' });
      const url = window.URL.createObjectURL(blob);
      setRecordedUrl(url);
    };

    return recorder;
  };

  const downloadVideo = () => {
    const link: HTMLAnchorElement = document.createElement("a");
    link.style.display = "none";
    link.href = recordedUrl;
    link.download = "video.webm";
    document.body.appendChild(link);
    link.click();

    setTimeout(() => {
      document.body.removeChild(link);
      window.URL.revokeObjectURL(recordedUrl);
      setRecordedUrl('');
    }, 100);
  };

  const initVideo = (shouldDownload: boolean) => {

    const { callToAction, productName, comparedAtPrice, price, accentColor, textColor, images } = templateData;

    // Override inline script custom options in index.html to use values from App state
    window.customOptions = {
      images: images.map((img: CustomImage) => img.url),
      heading: callToAction || '',
      productName: productName || '',
      comparedAtPrice: comparedAtPrice || '',
      price: price,
      accentColor: rgbString(hsbToRgb((accentColor))),
      textColor: rgbString(hsbToRgb((textColor))),
    };

    window.initSetup();

    window.init();

    const recorder = createMediaRecorder();

    const onAnimationStart = (): void => {
      setIsPlaying(true);
      if (shouldDownload) {
        recorder.start(1000);
        setIsRecording(true);
        setIsRecorded(false);
      }
    };

    const onAnimationEnd = (): void => {
      setIsPlaying(false);
      if (shouldDownload) {
        try {
          setIsRecording(false);
          setIsRecorded(true);
          recorder.stop();
        } catch (error) {
          console.error(error);
        }
      }
    };

    addCustomEvent(onAnimationStart, window, 'animationStarted');
    addCustomEvent(onAnimationEnd, window, 'videoIsOver');
  };

  const previewVideo: any = () => initVideo(false);
  const recordVideo: any = () => initVideo(true);

  return [previewVideo, recordVideo, downloadVideo, isRecording, isRecorded, isPlaying];
}
