import React, { useState, useEffect } from 'react';
import { Banner, Stack, List, DropZone, Card, InlineError, TextContainer, Link } from '@shopify/polaris';
import LoadingIcon from './LoadingIcon';
import ImageList from './ImageList';

const UPLOAD_PRESET = process.env.REACT_APP_UPLOAD_PRESET as string;
const CLOUD_NAME = process.env.REACT_APP_CLOUD_NAME as string;

type Props = {
    images: CustomImage[],
    setImages: Function
}

function ImageUpload(props: Props) {

    const { images, setImages } = props;

    const [uploading, setUploading] = useState(false);

    const [rejectedFiles, setRejectedFiles] = useState<File[]>([]);

    const [openFileDialog, setOpenFileDialog] = useState(false);

    const hasError = rejectedFiles.length > 0;

    const handleDropZoneDrop = (_dropFiles: File[], acceptedFiles: File[], _rejectedFiles: File[]) => {

        setUploading(true);

        Promise.all(acceptedFiles.map((file) => offloadImage(file)))
            .then((response) => {
                response.forEach((image, i) => {
                    const imageURL = image.secure_url.replace(/upload\//g, "upload/w_800/"); // 800 is our canvas width for preview animation
                    setImages((images: CustomImage[]) => ([...images, { blob: acceptedFiles[i], url: imageURL }]));
                })
                setUploading(false);
            })
            .catch((err) => console.log(err));

        setRejectedFiles(_rejectedFiles);
    }

    useEffect(() => { if (openFileDialog === true) setOpenFileDialog(false) }, [openFileDialog]);

    // offload image to Cloudinary service
    const offloadImage = (image: File) => {
        const formData: FormData = new FormData();
        formData.append('file', image);
        formData.append('upload_preset', UPLOAD_PRESET);
        return fetch(`https://api.cloudinary.com/v1_1/${CLOUD_NAME}/upload`, { method: 'POST', body: formData })
            .then(response => response.json())
            .catch((err) => console.log(err));;
    }

    const fileUpload = !images.length && <DropZone.FileUpload actionTitle="Add Images" />;

    const uploadedFiles = images.length > 0 && <ImageList images={images} setImages={setImages} />

    const ErrorWithLink = () => <p>You need to upload 3 images <Link onClick={() => setOpenFileDialog(true)}>Add Images</Link></p>

    const RejectedFilesError = hasError && (
        <Banner
            title="The following images couldn’t be uploaded:"
            status="critical"
        >
            <List type="bullet">
                {rejectedFiles.map((file, index) => (
                    <List.Item key={index}>
                        {`"${file.name}" is not supported. File type must be .jpg, .png or .jpeg.`}
                    </List.Item>
                ))}
            </List>
        </Banner>
    );

    return <Stack vertical>
        {RejectedFilesError}

        <Card sectioned>
            {images.length < 3 && images.length > 0 && <TextContainer>
                <InlineError message={<ErrorWithLink />} fieldID="" /><br />
            </TextContainer>}

            {images.length > 3 && <TextContainer>
                <InlineError message="Only 3 images are allowed" fieldID="" /><br />
            </TextContainer>}

            <DropZone accept="image/jpg, image/jpeg, image/png" type="image" openFileDialog={openFileDialog} allowMultiple onDrop={handleDropZoneDrop} active={false}>
                {uploading && <LoadingIcon />}
                {
                    !uploading && <React.Fragment>
                        {uploadedFiles}
                        {fileUpload}
                    </React.Fragment>
                }
            </DropZone>
        </Card>
    </Stack>
}

export default ImageUpload;
