import React from 'react';
import { Spinner } from '@shopify/polaris';
import styled from '@emotion/styled';

const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
`

const LoadingIcon: React.FC = () => {
    return <LoadingContainer>
        <Spinner
            accessibilityLabel="Spinner example"
            size="large"
            color="teal"
        />
    </LoadingContainer>;
}

export default LoadingIcon;