import React from 'react'
import { Stack, Thumbnail, Caption } from '@shopify/polaris';
import styled from '@emotion/styled';

type Props = {
    images: CustomImage[],
    setImages: Function
}

const RemoveButton = styled.button`
    background: none;
    border: 0;
    border-radius: 0;
    color: var(--p-interactive, #006fbb);
    cursor: pointer;
    font-size: 1.2rem;

    &:hover {
        text-decoration: underline;
    }
`

function humanizeFileSize(bytes: number): string {

    const MB = bytes / (1024 * 1024);

    return MB < 1 ? `${(bytes / (1024)).toFixed(0)} KB` : `${MB.toFixed(2)} MB`

}

export default function ImageList(props: Props) {

    const { images, setImages } = props;

    const handleRemoveImage = (e: MouseEvent, index: number) => {
        e.stopPropagation();
        const newFiles = images.slice(0, index).concat(images.slice(index + 1, images.length))
        setImages(newFiles);
    }

    return (
        <Stack vertical>
            {images.map((image: CustomImage, index: number) => {

                const fileSize = humanizeFileSize(image.blob.size);

                return <Stack alignment="center" key={index}>
                    <Thumbnail
                        size="medium"
                        alt=""
                        source={image.url}
                    />
                    <div>
                        {image.blob.name} <Caption>{fileSize}</Caption>
                    </div>
                    <RemoveButton onClick={(e: any) => handleRemoveImage(e, index)}>Remove</RemoveButton>
                </Stack>
            }
            )}
        </Stack>
    )
}
