import { useState, useEffect } from "react";

function useAnimationScript(src: string): boolean[] {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const script: HTMLScriptElement = document.createElement("script");
        script.src = src;
        script.id = 'animationScript'
        document.body.appendChild(script);
        script.onload = () => setLoading(false);
        // console.log(`Loading ${src}`);

        return () => {
            document.body.removeChild(script);
            // console.log(`Removing ${src}`);
        }
    }, [src]);

    return [loading];
}

export default useAnimationScript;