import React, { useEffect, } from "react";
import styled from '@emotion/styled';

type Props = {
    templateState: Template,
    canvasRef: React.RefObject<CustomHTMLCanvasElement>,
    previewVideo: Function
}

const Wrapper = styled.div`
    width: 100%;
    background: black;
    align-items: center;
    justify-content: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    height: calc(100vh - 80px);
`;

const Container = styled.div`
    background-color: rgba(255, 255, 255, 1.00);
    position: relative;
    background: #000;
    width: 100%;
`

const Canvas = styled.canvas`
    position: absolute;
    display: block;
    background-color: rgba(255, 255, 255, 1.00);
    width: 100%;
    background: black;
    align-items: center;
    justify-content: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    height: calc(100vh - 80px);
`

const Overlay = styled.div`
    pointer-events: none;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    display: block;
`

export const PreviewBox = (props: Props) => {

    const { canvasRef, previewVideo } = props;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => previewVideo(), []);

    return (
        <Wrapper className="AnimateCanvas__Wrapper">
            <Container id="AnimateCanvas_Container">
                <Canvas id="canvas" ref={canvasRef} />
                <Overlay id="AnimateCanvas_Overlay" />
            </Container>
        </Wrapper>
    );
};
