import React, { useState } from 'react'

const initialState: TemplateColors = {
    accentColor: {
        hue: 0,
        saturation: 0.65,
        brightness: 1,
        alpha: 1,
    },
    textColor: {
        hue: 0,
        saturation: 100,
        brightness: 0,
        alpha: 1,
    },
}

type ContextType = {
    colors: TemplateColors,
    setColors: Function,
    colorsApplied: boolean,
    setColorsApplied: Function
}

const ColorContext = React.createContext<ContextType>({ colors: initialState, setColors: Function, colorsApplied: false, setColorsApplied: Function });

const ColorContextProvider: React.FC = (props) => {

    const [colors, setColors] = useState(initialState);

    const [colorsApplied, setColorsApplied] = useState(false);

    const { Provider } = ColorContext;

    return (
        <Provider value={{ colors, setColors, colorsApplied, setColorsApplied }} {...props} />
    )
}

export { ColorContextProvider, ColorContext };
