import React from "react";
import { Card, FormLayout, TextField } from "@shopify/polaris";

type Props = {
    value: TemplateData,
    setValue: Function,
}

function ContentSettings(props: Props) {

    const { value, setValue } = props;

    const { productName, comparedAtPrice, price, callToAction } = value;

    function handleInputChange(value: string, id: string): void {
        setValue((prevFormFields: TemplateData) => ({ ...prevFormFields, [id]: value }))
    }

    return <Card sectioned>
        <FormLayout>
            {typeof callToAction !== 'undefined' &&
                <TextField type="text" id="callToAction" value={callToAction} label="Call to Action" onChange={handleInputChange} />}

            {typeof productName !== 'undefined' &&
                <TextField type="text" id="productName" value={productName} label="Product name" onChange={handleInputChange} />}

            {typeof comparedAtPrice !== 'undefined' &&
                <TextField type="text" id="comparedAtPrice" value={comparedAtPrice} label="Compared at price" onChange={handleInputChange} />}

            <TextField type="text" id="price" value={price} label="Price" onChange={handleInputChange} />
        </FormLayout>
    </Card>
}

export default ContentSettings;