import React, { useState } from 'react'

const initialState: Template = {
    callToAction: '',
    productName: '',
    price: '',
    comparedAtPrice: '',
    name: '',
    scriptFile: '',
    slug: '',
    imageSrc: '',
    imageAlt: ''
}

const FormContext = React.createContext<{ value: Template, setValue: Function }>({ value: initialState, setValue: () => { } });

const FormContextProvider: React.FC = (props) => {

    const [value, setValue] = useState(initialState)

    const { Provider } = FormContext;

    return (
        <Provider value={{ value, setValue }} {...props} />
    )
}

export { FormContextProvider, FormContext }
