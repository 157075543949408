import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import * as serviceWorker from './serviceWorker';
import '@shopify/polaris/styles.css';
import { AppProvider, Banner, Page } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';

const NotSupportedInfo: React.FC = () => <Page>
  <Banner
    title="Your browser is not supported by this website."
    status="critical"
  >
    <p>Currently, this website is only supported by latest versions of the following browsers:</p>
    <ul>
      <li>Google Chrome on Desktop</li>
      <li>Google Chrome on Android devices</li>
      <li>Mozilla Firefox on Desktop</li>
      <li>Microsoft Edge on Desktop</li>
      <li>Samsung Internet on Android</li>
    </ul>
  </Banner>
</Page>

ReactDOM.render(
  <React.StrictMode>
    <AppProvider
      i18n={enTranslations}
      features={{ newDesignLanguage: true }}
    >
      {'MediaRecorder' in window ? <App /> : <NotSupportedInfo />}
    </AppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
