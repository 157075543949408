import awakeningImage from "./images/circles/images/preview.gif";
import gamerBubblesImage from "./images/GamerBubbles/images/preview.gif";
import militaryTechImage from "./images/MilitaryTech/images/preview.gif";
import theEdisonImage from "./images/TheEdison/images/preview.gif";

const templates: Array<TemplateWithColors> = [
    {
        name: "Awakening",
        slug: 'awakening',
        imageSrc: awakeningImage,
        imageAlt: "",
        productName: "Limited Time Offer",
        comparedAtPrice: "-50%",
        price: "$50.00",
        accentColor: {
            hue: 53,
            saturation: 0.65,
            brightness: 1,
            alpha: 1,
        },
        textColor: {
            hue: 0,
            saturation: 100,
            brightness: 0,
            alpha: 1,
        },
        scriptFile: '/animations/01_circles.js',
    },
    {
        name: "Twenty 20",
        slug: 'twenty-20',
        imageSrc: theEdisonImage,
        imageAlt: "",
        productName: "Limited Time Offer",
        callToAction: 'Get Yours Now!',
        comparedAtPrice: "-50%",
        price: "$50.00",
        accentColor: {
            hue: 198,
            brightness: 0.6,
            saturation: 0.8,
            alpha: 1,
        },
        textColor: {
            hue: 209.55223880597015,
            saturation: 0.88955078125,
            brightness: 0.23750000000000004,
            alpha: 1,
        },
        scriptFile: '/animations/02_carousel.js'
    },
    {
        name: "Military Tech",
        slug: 'military-tech',
        imageSrc: militaryTechImage,
        imageAlt: "",
        productName: "The Edison",
        callToAction: "Limited Time Offer",
        price: "$50.00",
        accentColor: {
            hue: 99.40298507462687,
            saturation: 0.95,
            brightness: 0.4625,
            alpha: 1,
        },
        textColor: {
            hue: 99.40298507462687,
            saturation: 0.9625,
            brightness: 0.16249999999999998,
            alpha: 1,
        },
        scriptFile: '/animations/03_tech.js'
    },
    {
        name: "Gamer bubbles",
        slug: 'gamer-bubbles',
        imageSrc: gamerBubblesImage,
        imageAlt: "",
        productName: "The Edison",
        callToAction: "Limited Time Offer",
        price: "$50.00",
        accentColor: {
            hue: 180,
            saturation: 0.9125,
            brightness: 0.7625,
            alpha: 1,
        },
        textColor: {
            hue: 99.40298507462687,
            saturation: 0.9625,
            brightness: 0.16249999999999998,
            alpha: 1,
        },
        scriptFile: '/animations/04_gamerbubbles.js',
    },
]

export default templates