import React, { useState, useContext, useEffect } from 'react';
import { Page, Layout, Card, Button, Stack, TextContainer, Heading } from '@shopify/polaris';
import templates from '../../data';
import ImageUpload from "./ImageUpload";
import ColorPickers from "./ColorPickers";
import ContentSettings from "./ContentSettings";
import TemplateSVG from './TemplateSVG';
import useAnimationScript from '../../util/useAnimationScript';
import styled from '@emotion/styled';
import TemplateGrid from './TemplateGrid';
import { RouteComponentProps } from '@reach/router';
import { navigate } from '@reach/router'
import { ImageUploadContext } from '../../context/ImageUploadContext';
import { FormContext } from '../../context/FormContext';
import { ColorContext } from '../../context/ColorContext';
import TemplatePreview from '../TemplatePreview/index';

const ButtonWrapper = styled.div`
  display: none;

  @media (min-width: 767px) {
      display: block;
      padding-top: 2rem;
  }
`

const MobilePreviewButtonContainer = styled.div`
  display: block;
  position: fixed;
  bottom: 1rem;
  left: 0;
  width: 100%;
  z-index: 100;
  padding: 0 2rem;

  @media (min-width: 767px) {
    display: none;
    width: 100%;
  }
`

const MobileSpaceBottom = styled.div`
    @media (max-width: 767px) {
        padding-bottom: 4rem;
    }
`

function TemplateEdit(props: RouteComponentProps | any) {

    const { templateSlug } = props; // From Router

    const { images, setImages } = useContext(ImageUploadContext);

    const { colors, setColors, colorsApplied, setColorsApplied } = useContext(ColorContext);

    const { value, setValue } = useContext(FormContext);

    const { name, scriptFile } = value;

    const [showPreview, setShowPreview] = useState(false);

    // Execute the animation script for template
    const [loading] = useAnimationScript(scriptFile);

    const disablePreviewButton = images.length !== 3 && !loading;

    const templateState: Template = { ...value, ...colors, images: images };

    useEffect(() => {
        const templateData: TemplateWithColors = templates.find((template) => template.slug === templateSlug)
        const { accentColor, textColor, ...rest } = templateData;
        setValue({ ...rest })
        if (!colorsApplied) {
            setColors({ accentColor, textColor });
            setColorsApplied(true);
        }
        navigate(templateSlug);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateSlug])

    const handlePreview = () => setShowPreview(true);

    const PreviewBtn = () => <Button fullWidth size="large" disabled={disablePreviewButton} primary onClick={handlePreview}>Preview and Download</Button>

    return (showPreview ? <TemplatePreview setShowPreview={setShowPreview} templateState={templateState} /> : <Page
        fullWidth
        breadcrumbs={[{
            content: 'Template selection', onAction: () => navigate('/')
        }]}
        title={name}
        separator>
        <Layout>
            <Layout.Section>
                <Layout>
                    <Layout.Section>
                        <Stack vertical>
                            <TextContainer>
                                <Heading>1. Upload 3 product images</Heading>
                                <p>We will use these 3 images to generate your video. Please provide images with 1:1 aspect ratio.</p>
                            </TextContainer>
                            <ImageUpload images={images} setImages={setImages} />
                        </Stack>
                    </Layout.Section>
                    <Layout.Section>
                        <Stack vertical>
                            <TextContainer>
                                <Heading>2. Visual Settings</Heading>
                                <p>We will use these to highlight few elements.</p>
                            </TextContainer>
                            <ColorPickers colors={colors} setColors={setColors} />
                        </Stack>
                    </Layout.Section>
                    <Layout.Section>
                        <Stack vertical>
                            <TextContainer>
                                <Heading>3. Content Settings</Heading>
                                <p>We will use this information to describe your Video.</p>
                            </TextContainer>
                            <ContentSettings value={value} setValue={setValue} />
                        </Stack>
                    </Layout.Section>
                </Layout>
            </Layout.Section>
            <Layout.Section secondary>
                <Card sectioned>
                    <div className="template-preview-image">
                        <TemplateSVG />
                    </div>
                </Card>
                <Card title="More Templates">
                    <TemplateGrid />
                </Card>
                <ButtonWrapper>
                    <PreviewBtn />
                </ButtonWrapper>
                <MobileSpaceBottom />
            </Layout.Section>
            <Layout.Section>
                <MobilePreviewButtonContainer>
                    <PreviewBtn />
                </MobilePreviewButtonContainer>
            </Layout.Section>
        </Layout>
    </Page>);
}

export default TemplateEdit;
