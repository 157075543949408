import React, { useContext } from 'react';
import { Page, Layout, Heading, Card, Button, Stack } from '@shopify/polaris';
import styled from '@emotion/styled';
import templates from '../../data';
import './template.css';
import { RouteComponentProps, navigate } from '@reach/router';
import { ColorContext } from '../../context/ColorContext';
import { FormContext } from '../../context/FormContext';


const TemplatesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-left: -2rem;
    margin-right: -2rem;

    @media screen and (max-width: 767px) {
        margin-left: -1rem;
        margin-right: -1rem;
    }
`

const TemplateCardContainer = styled.div`
    width: 25%;
    padding: 0 2rem;
    margin: 0 0 2rem;

    @media screen and (max-width: 991px) {
        width: 33.33%;
    }

    @media screen and (max-width: 767px) {
        width: 50%;
        padding: 0 1rem;
    }

    @media screen and (max-width: 320px) {
        width: 100%;
    }
`

const CustomPolarisButtonWrapper = styled.div`
    @media screen and (max-width: 568px) and (min-width: 321px) {
        .Polaris-Button__Content {
            font-size: 11px;
        }
    }
`

function TemplateList(props: RouteComponentProps) {

    const { setColors, colorsApplied, setColorsApplied } = useContext(ColorContext);

    const { setValue } = useContext(FormContext);

    const handleSelectTemplate = (slug: string) => {
        const templateData: TemplateWithColors = templates.find((template) => template.slug === slug)
        const { name, accentColor, textColor, productName, comparedAtPrice, price, callToAction, scriptFile } = templateData;
        setValue({ name, productName, comparedAtPrice, price, callToAction, scriptFile })
        if (!colorsApplied) {
            setColors({ accentColor, textColor });
            setColorsApplied(true);
        }
        navigate(slug);
    }

    return <Page
        fullWidth
        title="Online Video Maker"
        separator>
        <Layout>
            <Layout.Section fullWidth>
                <Heading element="h1">Choose your Ad Template</Heading>
            </Layout.Section>
            <Layout.Section>
                <TemplatesContainer>
                    {templates.map(({ name, imageSrc, imageAlt, slug }, index: number) => {
                        return <TemplateCardContainer key={slug}>
                            <Card title={name}>
                                <Card.Section>
                                    <Stack spacing="loose" vertical>
                                        <div className="template-image">
                                            <img src={imageSrc} alt={imageAlt} />
                                        </div>
                                        <Stack distribution="trailing">
                                            <CustomPolarisButtonWrapper>
                                                <Button key={index} primary onClick={() => handleSelectTemplate(slug)}
                                                >Use this template</Button>
                                            </CustomPolarisButtonWrapper>
                                        </Stack>
                                    </Stack>
                                </Card.Section>
                            </Card>
                        </TemplateCardContainer>
                    })}
                </TemplatesContainer>
            </Layout.Section>
        </Layout>
    </Page>;
}

export default TemplateList;
