import React, { useRef, useEffect, useState } from 'react';
import { Layout, TextContainer, Card, ButtonGroup, Button, Stack, Heading } from '@shopify/polaris';
import CloseButton from './CloseButton';
import { PreviewBox } from './PreviewBox';
import useDownload from '../../util/useDownload'
import EmailModal from './EmailModal';
import { RouteComponentProps } from '@reach/router';
import RecordingOverlay from './RecordingOverlay';
import styled from '@emotion/styled';

const PreviewContainer = styled.div`
    position: relative;
    background: #000;
    width: 100%;
`

interface Props extends RouteComponentProps {
    templateState: Template,
    setShowPreview: Function,
}

function TemplatePreview(props: Props) {

    const { templateState, setShowPreview } = props;

    const canvasRef = useRef<CustomHTMLCanvasElement>(null);

    const [previewVideo, recordVideo, downloadVideo, isRecording, isRecorded, isPlaying] = useDownload(templateState, canvasRef);

    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        setModalOpen(isRecorded);
    }, [isRecorded]);

    return <Layout>
        <Layout.Section fullWidth>
            <Card sectioned>
                <Stack distribution="equalSpacing" alignment="center">
                    <TextContainer spacing="tight">
                        <Heading>Online Video Maker</Heading>
                        <p>Video ad preview</p>
                    </TextContainer>
                    <Stack alignment="center">
                        <ButtonGroup>
                            <Button disabled={isPlaying} onClick={() => previewVideo()}>Play again</Button>
                            <Button primary disabled={isRecording} onClick={recordVideo}>Download Video</Button>
                        </ButtonGroup>
                        <CloseButton setShowPreview={setShowPreview} />
                    </Stack>
                </Stack>
            </Card>
        </Layout.Section>
        <PreviewContainer>
            <RecordingOverlay isRecording={isRecording} isPlaying={isPlaying} previewVideo={previewVideo} />
            <PreviewBox templateState={templateState} canvasRef={canvasRef} previewVideo={previewVideo} />
        </PreviewContainer>
        <EmailModal open={modalOpen} setModalOpen={setModalOpen} downloadVideo={downloadVideo} />
    </Layout>;
}

export default TemplatePreview;