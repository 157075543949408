import React, { useContext } from 'react';
import defaultImage from '../../images/video-preview.svg'
import previewFrame from '../../images/preview-frame.png'
import { hsbToRgb, rgbString } from '@shopify/polaris';
import { ImageUploadContext } from '../../context/ImageUploadContext';
import { ColorContext } from '../../context/ColorContext';
import { FormContext } from '../../context/FormContext';

const TemplateSVG = () => {

    const { value } = useContext(FormContext);

    const { name, productName, price, comparedAtPrice } = value;

    const { images } = useContext(ImageUploadContext);

    const { colors } = useContext(ColorContext);

    const { textColor, accentColor } = colors;

    const color: string = rgbString(hsbToRgb((textColor)));

    const accColor: string = rgbString(hsbToRgb((accentColor)))

    const previewImage: string = images.length > 0 ? images[0].url : defaultImage;

    switch (name) {
        case "Awakening":
            return <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 513.7 506.78"><image width="1386" height="1386" transform="translate(37.6 35.48) scale(0.31)" xlinkHref={previewImage}></image><path fill={accColor} d="M9.38 6.13h224.37v64.46H9.38zM375.94 442.31h137.39v64.46H375.94z" opacity=".4" /><path fill="none" stroke={accColor} strokeMiterlimit="10" strokeWidth="3" d="M1.5 251.34v252.65h291.35M512.2 337.04V1.5H355.64" /><path fill="red" d="M433.26 403.38h72.12v32.23h-72.12z" /><path fill={accColor} d="M368 435.61h137.39v64.46H368zM2.62.55h224.37v64.46H2.62z" /><text fill={color} fontFamily="AmaticSC-Bold, Amatic SC" fontSize="36" fontWeight="700" transform="translate(21.13 43.83)">{productName}</text><text fill={color} fontFamily="AmaticSC-Bold, Amatic SC" fontSize="42" fontWeight="700" transform="translate(391.06 485.41)">{price}</text><text fill="#fff" fontFamily="AmaticSC-Bold, Amatic SC" fontSize="20" fontWeight="700" transform="translate(448.63 426.92)">{comparedAtPrice}</text></svg>
        case "Twenty 20":
            return <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 509.23 509.23"><image width="1386" height="1386" transform="translate(37.6 35.48) scale(0.31)" xlinkHref={previewImage}></image><text transform="translate(375.99 476.61)" fontSize="50" fontFamily="AmaticSC-Bold, Amatic SC" fontWeight="700" fill={color}>{price}</text><text transform="translate(466 397.17)" fontSize="33" fill={accColor} fontFamily="AmaticSC-Bold, Amatic SC" fontWeight="700" textAnchor="end">{productName}</text><rect x="1.5" y="1.5" width="506.23" height="506.23" fill="none" stroke="#000000" strokeMiterlimit="10" strokeWidth="3"></rect><text transform="translate(419.66 427.85)" fontSize="25" fill="#666" fontFamily="AmaticSC-Bold, Amatic SC" textDecoration="line-through" fontWeight="700">{comparedAtPrice}</text></svg>;
        case "Military Tech":
            return <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 600 600"><image width="936" height="936" transform="scale(0.64)" xlinkHref={previewFrame}></image><image id="image" width="1400" height="1390" transform="translate(48.52 49.64) scale(0.36 0.36)" xlinkHref={previewImage}></image><rect x="237.45" y="492.11" width="317.3" height="45.87" fill={color}></rect><rect x="378.35" y="428.52" width="176.4" height="63.59" fill={accColor}></rect><text transform="translate(390.63 481.03)" fontSize="45.15" fill="#fff" fontFamily="Impact">{price}</text><text transform="translate(390.23 530.15)" fontSize="33" fill="#fff" fontFamily="Impact">{productName}</text></svg>
        case "Gamer bubbles":
            return <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="100 100 650 650"><image width="1400" height="1390" transform="translate(158.51 186.42) scale(0.36 0.36)" xlinkHref={previewImage}></image><rect id="red" x="152.48" y="641.17" width="317.3" height="51.48" fill={accColor}></rect><text transform="translate(164.77 630.08)" fontSize="53.15" fontFamily="Impact" fill={color}>{price}</text><text transform="translate(163.64 680.2)" fontSize="33" fill="#fff" fontFamily="Impact">{productName}</text><g id="accentColorShift"><circle cx="237.27" cy="51.94" r="8.24" transform="translate(104.41 192.69) rotate(-13.28)" fill="#C211C2"></circle><circle cx="149.03" cy="316.33" r="8.1" fill="#C211C2"></circle><circle cx="552.6" cy="697.14" r="8.1" fill="#C211C2"></circle></g><g id="accentColor"><circle cx="34.01" cy="7.22" r="144" transform="translate(114.85 162.94) rotate(-45)" fill={accColor}></circle><circle cx="225.73" cy="7.22" r="27.63" transform="translate(171 298.51) rotate(-45)" fill={accColor}></circle><circle cx="102.14" cy="329.02" r="24.8" fill={accColor}></circle><circle cx="201.13" cy="49.64" r="13.54" transform="translate(103.97 184.32) rotate(-13.28)" fill={accColor}></circle><circle cx="752.93" cy="743.3" r="144" fill={accColor}></circle><circle cx="449.82" cy="606.52" r="24.8" transform="translate(-187.13 632.5) rotate(-45)" fill={accColor}></circle><circle cx="479.42" cy="560.35" r="10.91" transform="translate(26.42 220.81) rotate(-9.22)" fill={accColor}></circle></g></svg>
        default:
            return <p>Cannot find matching Template</p>
    }
}

export default TemplateSVG;