import React from 'react';
import { Card, ColorPicker, Stack, TextContainer, hsbToRgb, rgbString, } from '@shopify/polaris';

type ColorPickerProps = {
    title: string,
    name: string,
    colors: HsbaColor,
    setColors: Function,
}

function OVMColorPicker(props: ColorPickerProps) {

    const { title, name, colors, setColors } = props;

    const handleChange = (newColor: HsbaColor) => {
        setColors((currentColors: TemplateColors) => ({ ...currentColors, [name]: newColor }));
    }

    return <Stack vertical={true}>
        <TextContainer spacing="loose">{title}
            <span style={{
                backgroundColor: rgbString(hsbToRgb((colors))), boxShadow: "inset 0 0 2px 0 rgba(0,0,0,.5)",
                width: 40,
                height: 20,
                borderRadius: 20,
                marginTop: 0,
                position: "absolute",
                display: "inline-block",
                marginLeft: 10,
            }}></span>
        </TextContainer>
        <ColorPicker allowAlpha onChange={handleChange} color={colors} />
    </Stack>
}

type ColorPickersProps = {
    colors: TemplateColors,
    setColors: Function,
}

function ColorPickers(props: ColorPickersProps) {

    const { colors, setColors } = props;

    const { accentColor, textColor } = colors;

    return <Card sectioned>
        <Stack distribution="fillEvenly">
            <OVMColorPicker title="Primary Text Color" name="textColor" colors={textColor} setColors={setColors} />
            <OVMColorPicker title="Accent Color" name="accentColor" colors={accentColor} setColors={setColors} />
        </Stack>
    </Card>
}

export default ColorPickers;
