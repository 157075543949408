import React, { useContext } from 'react';
import styled from '@emotion/styled';
import templates from '../../data';
import { FormContext } from '../../context/FormContext';
import { RouteComponentProps, navigate } from '@reach/router';

const Container = styled.div`
    display: flex;
    padding: 2rem;
    margin-left: -1rem;
    margin-right: -1rem;
`

const Template = styled.div`
    cursor: pointer;
    margin: 0 1rem;
`

const TemplateImage = styled.img`
    box-shadow: var(--p-card-shadow, 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15));
`

function TemplateGrid(props: RouteComponentProps) {

    const { setValue } = useContext(FormContext);

    const handleTemplateChange = (slug: string) => {
        const templateData: TemplateWithColors = templates.find((template) => template.slug === slug)
        const { name, productName, comparedAtPrice, price, heading, scriptFile } = templateData;
        setValue({ name, productName, comparedAtPrice, price, heading, scriptFile })
        navigate(slug);
    }

    return (
        <Container>
            {templates.map((template, index) => {
                const { imageSrc, imageAlt } = template;
                return (<Template key={index} onClick={() => handleTemplateChange(template.slug)
                }>
                    <TemplateImage src={imageSrc} alt={imageAlt} />
                </Template>)
            })}
        </Container >
    )
}

export default TemplateGrid;