import React, { useState, useCallback } from 'react';
import { Form, Link, Modal, TextStyle, TextField, TextContainer } from '@shopify/polaris';

type Props = {
    open: boolean,
    setModalOpen: Function,
    downloadVideo: Function
}

function EmailModal(props: Props) {

    const persistedEmail: string | null = localStorage.getItem('email');
    const [email, setEmail] = useState(persistedEmail || '');
    const [isValidEmail, setIsValidEmail] = useState(persistedEmail ? true : false);
    const [isSaving, setIsSaving] = useState(false);
    const { open, setModalOpen, downloadVideo } = props;

    function handleSubmit() {
        setIsSaving(true);
        localStorage.setItem('email', email);

        // TODO: Fire API call here and remove setTimeout
        setTimeout(() => {
            setIsSaving(false);
            downloadVideo();
        }, 3000);

        // Fire download video here
    }

    const handleEmailChange = useCallback((value) => {
        setEmail(value);
        setIsValidEmail(validateEmail(value));
        localStorage.setItem('email', email);
    }, [email]);


    const validateEmail = (email: string) => {
        // eslint-disable-next-line
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(String(email).toLowerCase());
    }

    return (
        <Modal
            open={open}
            onClose={() => setModalOpen(false)}
            title="🎉 Congratulations on your new Video. Please provide us your email and your download should begin automatically."
            primaryAction={{
                content: isSaving ? 'Please wait...' : 'Download Video',
                onAction: handleSubmit,
                disabled: (email === '') || !isValidEmail || isSaving,
            }}
        >
            <Modal.Section>
                <Form onSubmit={handleSubmit}>
                    <TextField
                        label="Your Email"
                        value={email}
                        type="email"
                        onChange={handleEmailChange}
                        placeholder="Example: hello@example.org"
                    />
                    <TextContainer>
                        <TextStyle variation="subdued">By entering your email, you agree to receive marketing emails from Burst. Information is collected in accordance with our <Link url="https://www.shopify.com/legal/privacy" external monochrome>Privacy Policy</Link>. You can unsubscribe any time.</TextStyle>
                    </TextContainer>
                </Form>
            </Modal.Section>
        </Modal>
    );
}

export default EmailModal;
