import React from 'react';
import styled from '@emotion/styled';

const Button = styled.div`
    background: none;
    border: none;
    cursor: pointer;
`

type Props = {
    setShowPreview: Function
}

function CloseButton(props: Props) {

    const { setShowPreview } = props;

    return <div className="TitleBar-Close">
        <Button onClick={() => setShowPreview(false)}>
            <span className="Polaris-Icon Polaris-Icon--colorInkLighter Polaris-Icon--isColored">
                <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg" focusable="false" aria-hidden="true"><path d="M11.414 10l4.293-4.293a.999.999 0 1 0-1.414-1.414L10 8.586 5.707 4.293a.999.999 0 1 0-1.414 1.414L8.586 10l-4.293 4.293a.999.999 0 1 0 1.414 1.414L10 11.414l4.293 4.293a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L11.414 10z" fillRule="evenodd"></path></svg>
            </span>
        </Button>
    </div>;
}

export default CloseButton;