import React, { useState } from 'react'

const ImageUploadContext = React.createContext<{ images: CustomImage[], setImages: Function }>({ images: [], setImages: () => { } });

const ImageUploadContextProvider: React.FC = (props) => {

    const [images, setImages] = useState<CustomImage[]>([])

    const { Provider } = ImageUploadContext;

    return (
        <Provider value={{ images, setImages }} {...props} />
    )
}

export { ImageUploadContextProvider, ImageUploadContext };
