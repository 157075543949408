import React from 'react';
import TemplateList from './components/TemplateList';
import TemplateEdit from './components/TemplateEdit';
import { Router, RouteComponentProps } from "@reach/router"
import { ImageUploadContextProvider } from './context/ImageUploadContext';
import { ColorContextProvider } from './context/ColorContext';
import { FormContextProvider } from './context/FormContext';

const NotFound = (props: RouteComponentProps) => <h1>Oops! This page does not exist.</h1>

function App() {
  return (
    <ImageUploadContextProvider>
      <ColorContextProvider>
        <FormContextProvider>
          <Router>
            <TemplateList path="/" />
            <TemplateEdit path="/:templateSlug" />
            <NotFound default />
          </Router>
        </FormContextProvider>
      </ColorContextProvider>
    </ImageUploadContextProvider>
  )
}

export default App;
