import React from 'react'
import styled from '@emotion/styled';
import { Stack, Link } from '@shopify/polaris';

const Overlay = styled.div`
    position: absolute;
    background: #000000d9;
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 1;
    color: white;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    font-size: 48px;

    svg {
        align-self: center;
        cursor: pointer;
        width: 100%;
        height: auto;
        transform: none;
    }
`

const Text = styled.span`
    margin-left: -20px;
  
    &::before {
        content: "";
        color: red;
        width: 20px;
        height: 20px;
        background: red;
        display: inline-block;
        border-radius: 50%;
        top: -6px;
        position: relative;
        margin-right: 10px;
        animation: fadeInOut 0.6s ease infinite alternate;
    }


    @keyframes fadeInOut {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`

const PlayAgainIcon: any = () => <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" transform="translate(11.9952 11.9952) scale(.9167)" viewBox="0 0 40 40">
    <path fill="#fff" d="M20 40C9 40 0 31 0 20S9 0 20 0c4.5 0 8.7 1.5 12.3 4.2.4.3.5 1 .2 1.4-.3.4-1 .5-1.4.2C27.9 3.3 24 2 20 2 10.1 2 2 10.1 2 20s8.1 18 18 18 18-8.1 18-18c0-3.2-.9-6.4-2.5-9.2-.3-.5-.1-1.1.3-1.4.5-.3 1.1-.1 1.4.3C39 12.9 40 16.4 40 20c0 11-9 20-20 20z" />
    <path fill="#fff" d="M30.5 20c0 .6-.4 1-1 1-.5 0-1-.4-1-1 0-4.7-3.8-8.6-8.5-8.6s-8.5 3.8-8.5 8.6c0 4.7 3.8 8.6 8.5 8.6l-1.7-1.7c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l3.1 3.1.2.1.2.2c.2.2.3.4.3.7 0 .3-.1.5-.3.7l-3.4 3.4c-.3.2-.6.3-.8.3s-.5-.1-.7-.3c-.4-.4-.4-1 0-1.4l1.7-1.7c-5.8 0-10.5-4.7-10.5-10.6S14.2 9.4 20 9.4 30.5 14.2 30.5 20z" />
</svg>

type Props = {
    isPlaying: boolean,
    isRecording: boolean,
    previewVideo: Function
}

export default function RecordingOverlay(props: Props) {

    const { isPlaying, isRecording, previewVideo } = props;

    return (
        <React.Fragment>
            {(isRecording || !isPlaying) &&
                <Overlay>
                    {isRecording && <Text>Recording</Text>}
                    {!isPlaying &&
                        <Stack vertical alignment="center">
                            <Link onClick={() => previewVideo()}><PlayAgainIcon /></Link>
                        </Stack>}
                </Overlay>}
        </React.Fragment>
    )
}
